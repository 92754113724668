@import "../../index.scss";
.tour-msg {
    border-radius: 8px !important;
    > span {
        display: none;
    }
    .reactour__close {
        top: 12px;
        right: 17px;
        width: 10px;
        height: 10px;
        color: #f58220;
    }
    .sc-bZQynM {
        display: none;
    }
}


.requestlistitem {
    padding-bottom: $fifty + $twenty;
    .requestlis {
        margin-bottom: $fifty;
        >.dragged {
            background: $color_white;
            box-shadow: 1px 1px 3px #0000000D;
            @include border-radius($five);
            padding: ($ten) $zero ($ten + 3) $zero;
            margin-bottom: $ten + 3;
            position: relative;
            border: 1px solid transparent;
            &.border-highlight {
                border-color: #565656;
                box-shadow: 0px 6px 10px #0000001A;
            }
            .flagimg {
                position: absolute;
                top: -8px;
                left: -14px;
                width: 30px;
                height: 30px;
                line-height: 36px;
                background: #f58220;
                border-radius: 100%;
                text-align: center;
            }

            .indication{
                margin-left: 0;
                position: absolute;
                top: -6px;
                right: -6px;
                width: 10px;
                height: 10px;
                background: #D61A1A;
                border-radius: 100%;
            }

            .when-added {
                display: inline-block;
                padding: 2px 8px;
                height: 25px;
                background: #CFF9D7;
                border-radius: 4px;
                margin-left: 20px;
                span {
                    display: inline-block;
                    letter-spacing: 0.24px;
                    color: #478152;
                    font-size: 12px;
                    font-weight: normal;
                }
            }
            
            .about-request {
                border-bottom: 2px solid $color_gainsboro;
                padding: 0 45px 8px 30px;
                span {
                    font-size: $ten + 3;
                    color: $color_matterhorn;
                    &.request-delete {
                        cursor: pointer;
                        font-size: $zero;
                        vertical-align: $middle;
                        &:before {
                            content: "";
                            @extend .sprite;
                            width: $twenty - 3;
                            height: $twenty - 3;
                            background-position: (-$hundred - $ten - $five) (-$fourty + 3);
                        }
                    }
                    &.request-drag {
                        font-size: $zero;
                        vertical-align: $middle;
                        cursor: url("../../assets/images/draghover.svg"), auto;
                        position: absolute;
                            right: 15px;
                            
                            top: 15px;
                            background-size: 11px;
                            background-repeat: no-repeat;
                                text-indent: -9999px;
                            width: 11px;
                            height: 18px;
                            background-image: url("../../assets/images/dragnew.svg");
                    }
                    &.request-no {
                        font-size: $twenty - 4;
                        font-weight: $Medium;
                        color: $color_matterhorn;
                        position: relative;
                        top:3px;
                    }
                    &.cursor-box {
                        font-size: $zero;
                        vertical-align: $middle;
                        cursor: pointer;
                        &:hover {
                            &:before {
                                background-position: (-$hundred - $fifty - $five - 1) (-$fourty + 1);
                            }
                        }
                        &:before {
                            content: "";
                            @extend .sprite;
                            width: $twenty - 2;
                            height: $twenty - 4;
                            background-position: (-$hundred - $fifty - $five - 1) (-$five);
                        }
                    }
                    &.copy-tool {
                        font-size: $zero;
                        vertical-align: $middle;
                        cursor: pointer;
                        &:before {
                            content: "";
                            @extend .sprite;
                            width: $twenty - 2;
                            height: $twenty - 2;
                            background-position: (-$hundred - $fifty - 3) (-$hundred * 2 - $ten - 2);
                        }
                    }
                    &.rating {
                        display: $inline-block;
                        .star {
                            margin: $zero $auto;
                            &::before {
                                content: "";
                                @extend .sprite;
                                width: $ten + $five;
                                height: $ten + 4;
                                background-position: (-$hundred - $fifty - $ten - 2) (-$hundred - $thirty + 3);
                            }
                            &.active {
                                &::before {
                                    background-position: (-$hundred - $fifty - $ten - 2) (-$hundred - $fourty - 3);
                                }
                            }
                        }
                    }
                    &.prioritized, &.prioritize, &.deprioritized {
                        min-width: 95px;
                        height: 25px;
                        border: 1px solid #000;
                        display: inline-block;
                        text-align: center;
                        line-height: 24px;
                        border-radius: 4px;
                        letter-spacing: 0.26px;
                        font-size: 13px;
                        text-transform: uppercase;
                        font-weight: 500;
                        color: #000;
                        background: transparent;
                        cursor: pointer;
                    }
                    &.prioritized {
                        background: #F58220;
                        color: #fff;
                        border-color: #F58220;
                    }
                }
                .request-right-section {
                    float: $right;
                    span {
                        margin-left: $twenty;
                        &:first-child {
                            margin-left: $zero;
                        }
                    }
                }
            }
            .request-detail {
                padding: $ten $thirty $zero;
                display: flex;
                justify-content: space-between;
                .title-and-comment {
                    float: $left;
                    position: $relative;
                    padding-left: $fifty + $ten;
                    flex: 0 0 63%;
                    .request-img {
                        width: $fourty;
                        height: $fourty;
                        background-position: (-$hundred - $ten - 3) (-$fifty - $twenty + 3);
                        position: $absolute;
                        left: $zero;
                        top: $five;
                    }
                    // &:before {
                    //     content: "";
                    //     @extend .sprite;
                    //     width: $fourty;
                    //     height: $fourty;
                    //     background-position: (-$hundred - $ten - 3) (-$fifty - $twenty + 3);
                    //     position: $absolute;
                    //     left: $zero;
                    //     top: $five;
                    // }
                    .title {
                        font-size: $twenty - 4;
                        line-height: $twenty + $five;
                        color: $color_black;
                        font-weight: $Semi-Bold;
                        margin: $zero;
                        a {
                            color: $color_black;
                        }
                    }
                    .comment-and-revision {
                        @include flexbox();
                        @include flex-wrap(wrap);
                        li {
                            &:before {
                                content: "|";
                                font-size: $ten + 3;
                                line-height: $twenty;
                                color: $color_gainsboro;
                                margin: $zero ($ten + 4);
                            }
                            &:first-child {
                                &:before {
                                    display: $none;
                                }
                            }
                            a {
                                font-size: $ten + 3;
                                line-height: $twenty;
                                color: $clor_skyblue;
                                display: $inline-block;
                            }
                        }
                    }
                }
                .name-and-status {
                    float: $right;
                    margin-top: $ten;
                    display: flex;
                    flex: 1;
                    justify-content: center;
                    align-items: flex-end;
                    flex-direction: column-reverse;
                    .request-added {
                        font-size: $ten + 3;
                        color: $color_matterhorn - 34;
                        margin-left: $twenty;
                    }
                }
            }
        }
    }
    .moredesignrequests {
        text-align: $center;
        a {
            color: $clor_skyblue;
            font-size: $ten + 3;
        }
    }
}

.prioritized {
    .tooltip-prioritize {
        display: none;
    }
}
.deprioritized, .prioritize {
    position: relative;
    .tooltip-prioritize {
        display: none;
    }
    &:hover {
        color: $color_white;
        .tooltip-prioritize {
            display: block;
            position: absolute;
            bottom: -42px;
            left: -108px;
            right: 0;
            min-width: 304px;
            padding: 5px 15px;
            background: #555;
            color: #ffffff !important;
            top: auto;
            font-size: 12px !important;
            font-weight: 400;
            text-transform: none;
            border-radius: 4px;
            box-shadow: 0px 20px 40px #00000066;
            font-family: "Poppins", arial, sans-serif;
            text-align: left;
            z-index: 1;
            &:after {
                content: "";
                position: absolute;
                top: -7px;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 7px solid #555;
                left: 0;
                right: 0;
                width: 8px;
                margin: 0 auto;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: $device__l) {
    .requestlistitem {
        padding-bottom: $thirty;
        .requestlis {
            margin-bottom: $thirty;
            >li {
                .indication {
                    top:-41px;
                    right: -37px;
                }
                .about-request {
                    .request-right-section {
                        width: $full;
                        position: $relative;
                        .due-date {
                            float: $left;
                            margin-left: $zero;
                        }
                        .last-updated {
                            float: $right;
                            margin-left: $zero;
                        }
                        .request-delete,
                        .request-no,
                        .cursor-box,
                        .rating,
                        .copy-tool {
                            position: $absolute;
                            margin: $zero;
                        }
                        .request-delete {
                            top: -$twenty;
                            right: $hundred - $twenty;
                        }
                        .request-no,
                        .rating {
                            right: $fourty;
                            top: (-$twenty - $five);
                        }
                        .cursor-box,
                        .copy-tool {
                            right: $zero;
                            top: (-$twenty);
                        }
                    }
                }
                .request-detail {
                    .title-and-comment,
                    .name-and-status {
                        width: $full;
                        .request-status {
                            float: $right;
                        }
                    }
                }
            }
        }
    }
    .requestlistitem {
        .requestlis > .item {
            .request-detail {
                .title-and-comment {
                    flex: 0 0 51%;
                }
            }
        }
    }
}

@media screen and (max-width: $device__m) {
    .requestlistitem {
        padding-bottom: $twenty;
        .requestlis {
            margin-bottom: $twenty;
            >li {
                .request-detail,
                .about-request {
                    padding-left: $ten + $five;
                    padding-right: $ten + $five;
                }
                .about-request {
                    .request-right-section {
                        .last-updated,
                        .time-spent,
                        .due-date {
                            width: $full;
                            margin-left: $zero;
                        }
                    }
                }
                .request-detail {
                    .title-and-comment {
                        padding-left: $fifty;
                    }
                    .name-and-status {
                        flex-direction: column;
                        .name-container{
                            text-align: center;
                            width: 100%;
                        }
                        .request-added {
                            width: $full;
                            display: $block;
                            text-align: $center;
                            margin: $zero $zero $five;
                        }
                        .request-status {
                            width: $full;
                            display: $block;
                            text-align: $center;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:$device__xs) {
    .requestlistitem .requestlis > li {
        .indication {
            right: -22px;
        }
    }
.deprioritized, .prioritize {
    &:hover {
        .tooltip-prioritize {
            left: -139px;
        }
    }
}
.requestlistitem .requestlis > li .about-request .request-right-section .copy-tool {
    right: 20px;
}
.requestlistitem {
    .requestlis .item {
        .request-detail {
            flex-wrap: wrap;
            .title-and-comment {
                flex: 0 0 100%;
            }
            .name-and-status {
                float: none;
                width: 100%;
                display: block;
                .request-status {
                    display: block;
                    margin-top: 10px;
                    text-align: center;
                }
                .name-container {
                    display: block;
                    text-align: center;
                }
            }
        }
        .when-added {
            width: 100%;
            display: block;
            margin-left: 0;
            text-align: center;
            margin-top: 10px;
        }
        
    }
}
}

.dragged {
    background: $color_white;
    box-shadow: 1px 1px 3px #0000000D;
    @include border-radius($five);
    padding: ($ten) $zero ($ten + 3) $zero;
    margin-bottom: $ten + 3;
    position: relative;
    border: 1px solid transparent;
    &.border-highlight {
        border-color: #565656;
        box-shadow: 0px 6px 10px #0000001A;
    }
    .flagimg {
        position: absolute;
        top: -8px;
        left: -14px;
        width: 30px;
        height: 30px;
        line-height: 36px;
        background: #f58220;
        border-radius: 100%;
        text-align: center;
    }

    .indication{
        margin-left: 0;
        position: absolute;
        top: -6px;
        right: -6px;
        width: 10px;
        height: 10px;
        background: #D61A1A;
        border-radius: 100%;
    }

    .when-added {
        display: inline-block;
        padding: 2px 8px;
        height: 25px;
        background: #CFF9D7;
        border-radius: 4px;
        margin-left: 20px;
        span {
            display: inline-block;
            letter-spacing: 0.24px;
            color: #478152;
            font-size: 12px;
            font-weight: normal;
        }
    }
    
    .about-request {
        border-bottom: 2px solid $color_gainsboro;
        padding: 0 45px 8px 30px;
        span {
            font-size: $ten + 3;
            color: $color_matterhorn;
            &.request-delete {
                cursor: pointer;
                font-size: $zero;
                vertical-align: $middle;
                &:before {
                    content: "";
                    @extend .sprite;
                    width: $twenty - 3;
                    height: $twenty - 3;
                    background-position: (-$hundred - $ten - $five) (-$fourty + 3);
                }
            }
            &.request-drag {
                font-size: $zero;
                        vertical-align: $middle;
                        cursor: url("../../assets/images/draghover.svg"), auto;
                        position: absolute;
                            right: 15px;
                            
                            top: 15px;
                            background-size: 11px;
                            background-repeat: no-repeat;
                                text-indent: -9999px;
                            width: 11px;
                            height: 18px;
                            background-image: url("../../assets/images/dragnew.svg");
            }
            &.request-no {
                font-size: $twenty - 4;
                font-weight: $Medium;
                color: $color_matterhorn;
                position: relative;
                top:3px;
            }
            &.cursor-box {
                font-size: $zero;
                vertical-align: $middle;
                cursor: pointer;
                &:hover {
                    &:before {
                        background-position: (-$hundred - $fifty - $five - 1) (-$fourty + 1);
                    }
                }
                &:before {
                    content: "";
                    @extend .sprite;
                    width: $twenty - 2;
                    height: $twenty - 4;
                    background-position: (-$hundred - $fifty - $five - 1) (-$five);
                }
            }
            &.copy-tool {
                font-size: $zero;
                vertical-align: $middle;
                cursor: pointer;
                &:before {
                    content: "";
                    @extend .sprite;
                    width: $twenty - 2;
                    height: $twenty - 2;
                    background-position: (-$hundred - $fifty - 3) (-$hundred * 2 - $ten - 2);
                }
            }
            &.rating {
                display: $inline-block;
                .star {
                    margin: $zero $auto;
                    &::before {
                        content: "";
                        @extend .sprite;
                        width: $ten + $five;
                        height: $ten + 4;
                        background-position: (-$hundred - $fifty - $ten - 2) (-$hundred - $thirty + 3);
                    }
                    &.active {
                        &::before {
                            background-position: (-$hundred - $fifty - $ten - 2) (-$hundred - $fourty - 3);
                        }
                    }
                }
            }
            &.prioritized, &.prioritize, &.deprioritized {
                min-width: 95px;
                height: 25px;
                border: 1px solid #000;
                display: inline-block;
                text-align: center;
                line-height: 24px;
                border-radius: 4px;
                letter-spacing: 0.26px;
                font-size: 13px;
                text-transform: uppercase;
                font-weight: 500;
                color: #000;
                background: transparent;
                cursor: pointer;
            }
            &.prioritized {
                background: #F58220;
                color: #fff;
                border-color: #F58220;
            }
        }
        .request-right-section {
            float: $right;
            span {
                margin-left: $twenty;
                &:first-child {
                    margin-left: $zero;
                }
            }
        }
    }
    .request-detail {
        padding: $ten $thirty $zero;
        display: flex;
        justify-content: space-between;
        .title-and-comment {
            float: $left;
            position: $relative;
            padding-left: $fifty + $ten;
            flex: 0 0 63%;
            .request-img {
                width: $fourty;
                height: $fourty;
                background-position: (-$hundred - $ten - 3) (-$fifty - $twenty + 3);
                position: $absolute;
                left: $zero;
                top: $five;
            }
            // &:before {
            //     content: "";
            //     @extend .sprite;
            //     width: $fourty;
            //     height: $fourty;
            //     background-position: (-$hundred - $ten - 3) (-$fifty - $twenty + 3);
            //     position: $absolute;
            //     left: $zero;
            //     top: $five;
            // }
            .title {
                font-size: $twenty - 4;
                line-height: $twenty + $five;
                color: $color_black;
                font-weight: $Semi-Bold;
                margin: $zero;
                a {
                    color: $color_black;
                }
            }
            .comment-and-revision {
                @include flexbox();
                @include flex-wrap(wrap);
                li {
                    &:before {
                        content: "|";
                        font-size: $ten + 3;
                        line-height: $twenty;
                        color: $color_gainsboro;
                        margin: $zero ($ten + 4);
                    }
                    &:first-child {
                        &:before {
                            display: $none;
                        }
                    }
                    a {
                        font-size: $ten + 3;
                        line-height: $twenty;
                        color: $clor_skyblue;
                        display: $inline-block;
                    }
                }
            }
        }
        .name-and-status {
            float: $right;
            margin-top: $ten;
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: flex-end;
            flex-direction: column-reverse;
            .request-added {
                font-size: $ten + 3;
                color: $color_matterhorn - 34;
                margin-left: $twenty;
            }
        }
    }
}
